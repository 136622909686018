import React from 'react';
import {
    Col, Container,
    Row
} from 'reactstrap';

const Experiencenya = () => {
    return (
        <section className="section section-lg bg-secondary">
            <Container>
                <Row className="justify-content-center text-center mb-md">
                    <Col lg="8">
                        <h2 className="display-3">My Experience</h2>
                        <p className="lead">
                            Experiences will keep you relaxed in facing all conditions.
                        </p>
                    </Col>
                </Row>
                <Row className="justify-content-center align-items-center">
                    <Col lg="10" className="timeline-wrapper">
                        <div className="timeline">
                            <ul>
                                <li>
                                    <div className="timeline-content">
                                        <span className="date">2019 - Present</span>
                                        <h2>PT Appkey</h2>
                                        <span className="role">Mobile Apps Developer (Flutter)</span>
                                        <p>Build clean architecture for the Flutter ecosystem<br></br>
                                            Develop and maintained mobile application based on Android  platform.<br></br>
                                            Develop Frontend web using Laravel Framework<br></br>
                                            Create unit test for testing
                                        </p>
                                    </div>
                                </li>
  
                                <li>
                                    <div className="timeline-content">
                                        <span className="date">2018 (Internship)</span>
                                        <h2>Indonesian Agency for Meteorology, Climatology, and Geophysics (BMKG)</h2>
                                        <span className="role">Security Analyst</span>
                                        <p>Develop Decision Support System and made Analysis security decision support system of BMKG website using penetration testing tools</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Experiencenya;