import React from 'react';
import {
    Button, Col, Container,
    Row
} from 'reactstrap';

const Aboutnya = () => {

    let data_about = "My name is Alit Tri Utari. I’m a graduate of 2019 from ITB Stikom Bali at Denpasar, Bali with a degree in Computer System. Experienced in Mobile Application Development (Android and iOS) especially using Flutter. My main job is developing Mobile Application, but i am also proficient in the mockup creation and web frontend if needed"

    return (
        <section id="section_about" className="section section-lg">
            <Container>
                <Row className="justify-content-center text-center mb-md">
                    <Col lg="8">
                        <h2 className="display-3">About Me</h2>
                        <p className="lead text-muted">
                            {data_about}
                        </p>
                    </Col>
                </Row>
                <Row className="row-grid align-items-center">
                    <Col lg="12" md="12" sm="12" xs="12">
                        <div className="px-4">
                            <img
                                className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                src="/images/photo_profile.jpg"
                                alt="Alit Tri Utari"
                                style={{width: "200px;"}}
                            />
                            <div className="pt-4 text-center">
                                <h5 className="title">
                                    <span className="d-block mb-1">Ni Komang Alit Tri Utari</span>
                                    <small className="h6 text-muted">Mobile Developer</small>
                                    <div className="h6 text-muted mt-1">Bali - Indonesia</div>
                                </h5>
                                <div className="mt-3">
                                    <Button className="btn-icon-only rounded-circle" color="primary" href="https://www.linkedin.com/in/alit-tri-utari//" target="_blank">
                                        <i className="fa fa-linkedin" />
                                    </Button>
                                    <Button className="btn-icon-only rounded-circle" color="primary" href="https://github.com/alittriutari/" target="_blank">
                                        <i className="fa fa-github" />
                                    </Button>
                                    
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Aboutnya;