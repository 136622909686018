import React from "react";
import {
    Badge,
    Button, Card,
    CardBody, Col, Container,
    Row
} from 'reactstrap';

const Projectnya = () => {

    let projectList = []
    let dataProject = [
        {
            photo: "restaurant.png",
            title: "Restaurant Catalog",
            description: "A simple catalog that shows information about nearby restaurant in Indonesia.",
            link: "https://github.com/alittriutari/RestaurantAppFlutter",
            tag: ["Flutter", "Android", "IOS", "Mobile"],
            type: -1
        },
        {
            photo: "movie.png",
            title: "Movie Catalog",
            description: "A simple catalog that shows information about Movies and Tv Series. Made using TheMovieDB api",
            link: "https://github.com/alittriutari/movie-app-flutter",
            tag: ["Flutter", "Android", "IOS", "Mobile"],
            type: -1
        },
        {
            photo: "ecommerce.png",
            title: "Ecommerce App",
            description: "An Ecommerce app build with Wordpress API",
            link: "#",
            tag: ["Flutter", "Android", "IOS", "Mobile"],
            type: 1
        },
        {
            photo: "sid.png",
            title: "SID 15th Anniversary",
            description: "A special edition app for SID (japanese visual key band) 15th anniversary. You can get your favorite member activity or new event on this app",
            link: "https://play.google.com/store/apps/details?id=com.appkey.sidapp",
            tag: ["Android", "Mobile"],
            type: 1
        },
        {
            photo: "news.png",
            title: "News App",
            description: "A simple app to read news. Made using NewsApi.org API (https://newsapi.org/)",
            link: "https://drive.google.com/file/d/1ZrbDFe3i61gHuwmJj7ClJatcX_DjkcCR/view?usp=sharing",
            tag: ["Flutter", "Android", "IOS", "Mobile"],
            type: 0
        },
        {
            photo: "dentish.png",
            title: "Dentish App",
            description: "A Dentish App that you can make the appointment with Dentish",
            link: "#",
            tag: ["Android"],
            type: 1
        },
        {
            photo: "limo.png",
            title: "Delivery App",
            description: "A Delivery App likes Uber Eats, build with Clean Architecture",
            link: "#",
            tag: ["Flutter", "Android", "IOS", "Mobile"],
            type: 1
        },

        {
            photo: "tbank.jpg",
            title: "Dating Website",
            description: "A website where you can find friends or partners",
            link: "#",
            tag: ["Laravel", "Web"],
            type: 1
        },
        // {
        //     photo: "rsbedinfo_banner.jpg",
        //     title: "Hospital Information App",
        //     description: "A mobile application that help you to find out hospital information in Indonesia.",
        //     link: "https://drive.google.com/file/d/1_VSZdE82O7dXQU9SY8gLt_uqRxJ6F2-Y/view?usp=sharing",
        //     tag: ["Android", "Kotlin", "Mobile"],
        //     type: -1
        // },
        // {
        //     photo: "aguadigi_banner.jpg",
        //     title: "Water Usage Monitoring App",
        //     description: "A mobile application that can help you to monitor water usage and water bills.",
        //     link: "#",
        //     tag: ["Android", "Kotlin", "Mobile"],
        //     type: 1
        // },
        // {
        //     photo: "eticket_banner.jpg",
        //     title: "Ticketing App",
        //     description: "A mobile application that can help you to order tickets for the tourism object.",
        //     link: "#",
        //     tag: ["Android", "Java", "Mobile"],
        //     type: 1
        // },
        // {
        //     photo: "spkt_banner.jpg",
        //     title: "Online Permission App",
        //     description: "A mobile application that can help you to request a document according to your needs.",
        //     link: "#",
        //     tag: ["Android", "Java", "Mobile"],
        //     type: 1
        // },
        // {
        //     photo: "tbm_banner.jpg",
        //     title: "Tour App",
        //     description: "A mobile application that can help you to create your tour plan in Bali easily.",
        //     link: "#",
        //     tag: ["IOS", "Swift", "Mobile"],
        //     type: 1
        // }
    ]

    dataProject.forEach((item, index) => {
        projectList.push(
            <Col lg="4">
                <Card className="card-lift--hover border-0 portfolio-card card-bottom-spacing">
                    <CardBody className="py-5">
                        <img
                            className="img-fluid rounded portfolio-img mb-4"
                            src={`/images/${item.photo}`}
                            alt={item.title}
                        />
                        <h6 className="text-uppercase portfolio-title">{item.title}</h6>
                        <p className="description mt-3">{item.description}</p>
                        <div>
                            {item.tag.map((item, index) => {
                                return <Badge color="primary" pill className="mr-1">{item}</Badge>
                            })}
                        </div>
                        <Button disabled={item.type === 1 ? true : false} className="mt-4" color="primary" href={item.link} target="_blank">
                            {item.type === 0 ? "Show Demo" : item.type === -1 ? "View on Github" : "Owned by Client"}
                        </Button>
                    </CardBody>
                </Card>
            </Col>
        )
    })

    return (
        <section id="section_project" className="section section-lg">
            <Container>
                <Row className="justify-content-center text-center mb-md">
                    <Col lg="8">
                        <h2 className="display-3">Portfolio</h2>
                        <p className="lead text-muted">
                           So far I contribute to 20 team and individual project, most of them i build using Flutter and Android (Java), this is my sample work
                        </p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg="12">
                        <Row className="row-grid">
                            {projectList}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
    
}

export default Projectnya;